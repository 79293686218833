import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../login/login.service';
import { IApn, IUpdateCliente } from 'modelos/src';
import { CrearEditarApnsComponent } from '../crear-editar-apns/crear-editar-apns.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ClientesService } from '../clientes.service';
import { HelperService } from 'src/app/auxiliares/helper.service';
import {
  IColumnas,
  ICrearBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { IRegExpSearch } from 'src/app/auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado-apns',
  templateUrl: './listado-apns.component.html',
  styleUrls: ['./listado-apns.component.scss'],
})
export class ListadoApnsComponent implements OnInit {
  public datos?: IApn[] = [];
  public columnas?: IColumnas<IApn>[];
  //public columnas = ['apn', 'usuario', 'password', 'acciones'];
  private idCliente?: string;
  public name = 'ListadoAuditoriasComponent';
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: [],
  };
  public botonCrear: ICrearBoton = {
    mostrar: true,
    tooltip: 'Crear APN',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };

  constructor(
    public matDialog: MatDialog,
    public service: ClientesService,
    public helper: HelperService,
  ) {}

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'APN',
        },
        row: {
          field: 'apn',
          parse(dato) {
            return dato.apn;
          },
        },
      },
      {
        header: {
          label: 'Usuario',
        },
        row: {
          field: 'usuario',
          parse(dato) {
            return dato.usuario;
          },
        },
      },
      {
        header: {
          label: 'Contraseña',
        },
        row: {
          field: 'password',
          parse(dato) {
            return dato.password;
          },
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              icon: 'assets/iconos/config.png',
              tooltip: 'Editar',
              click: (dato) => this.crear(dato),
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  public async crear(dato?: IApn) {
    console.log(dato);
    const config: MatDialogConfig = {
      width: '1200px',
      data: {
        dato,
        callback: this.getApns.bind(this),
      },
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarApnsComponent, config);
  }

  private async getData(apn: IApn) {
    let cliente;
    if (this.idCliente)
      cliente = await firstValueFrom(this.service.getPropio());
    const data: IUpdateCliente = {
      config: cliente?.config,
    };
    const indice = data.config?.apns?.findIndex(
      (item) => JSON.stringify(item) === JSON.stringify(apn),
    );
    data?.config?.apns?.splice(indice || 0, 1);
    data?.config?.apns?.sort();
    return data;
  }

  public async eliminar(apn: IApn) {
    console.log(apn);
    try {
      const data = await this.getData(apn);

      if (this.idCliente) {
        await firstValueFrom(this.service.editar(this.idCliente, data));
      }
      this.datos = data.config?.apns;
      this.helper.notifSuccess('Eliminado correctamente');
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  private async getApns() {
    console.log('apns' + (await firstValueFrom(this.service.getPropio())));
    this.idCliente = LoginService.getUsuario().idCliente;
    let cliente;
    if (this.idCliente)
      cliente = await firstValueFrom(this.service.getPropio());
    console.log(cliente);
    if (cliente) this.datos = cliente.config?.apns;
    console.log(this.datos);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    this.getApns();
  }
}
