import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IQueryParam, IListado, ILogNuc } from 'modelos/src';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from 'src/app/auxiliares/tabla/filtro/filtro.component';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { Subscription } from 'rxjs';
import { UsuariosService } from 'src/app/modulos/usuarios/usuarios.service';
import { MostrarBodyComponent } from './mostrar-body/mostrar-body.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';

interface ILogTabla extends ILogNuc {
  color: string;
}

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
})
export class ListadoComponent implements OnInit {
  public loading = true;
  public deveuiS?: string;

  // TABLA
  public dataSource = new MatTableDataSource<ILogTabla>([]);
  public totalCount = 0;
  public name = 'ListadoComponent';
  public datos?: ILogNuc[] = [];
  public columnas?: IColumnas<ILogNuc>[];
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['deveui'],
  };
  public tipoMensaje: IFiltroTabla = {
    elementos: [
      { nombre: 'ISetAlerta', _id: 'ISetAlerta' },
      { nombre: 'ISetAlertaV2', _id: 'ISetAlertaV2' },
      { nombre: 'ISetConfiguracion', _id: 'ISetConfiguracion' },
      { nombre: 'ISetConfiguracionV2', _id: 'ISetConfiguracionV2' },
      { nombre: 'ISetCorrectora', _id: 'ISetCorrectora' },
      { nombre: 'ISetCorrectoraV3', _id: 'ISetCorrectoraV3' },
      { nombre: 'ISetCromatografia', _id: 'ISetCromatografia' },
      { nombre: 'ISetRegistro', _id: 'ISetRegistro' },
      { nombre: 'ISetRegistroV3', _id: 'ISetRegistroV3' },
      { nombre: 'ISetReporte', _id: 'ISetReporte' },
      { nombre: 'ISetReporteV3', _id: 'ISetReporteV3' },
      { nombre: 'IGetConfiguracionV2', _id: 'IGetConfiguracionV2' },
      { nombre: 'IGetCromatografia', _id: 'IGetCromatografia' },
      { nombre: 'IGetRegistro', _id: 'IGetRegistro' },
    ],
    filter: {
      field: 'tipo',
    },
    label: 'Tipo de Mensaje',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public fecha: IFiltroTabla = {
    filter: {
      field: 'fecha',
    },
    label: 'Fecha',
    tipo: 'dateRange',
  };
  public codigoRespuesta: IFiltroTabla = {
    elementos: [
      { nombre: '200', _id: '200' }, //Ok
      { nombre: '400', _id: '400' }, //Bad Request
      { nombre: '401', _id: '401' }, // Unautorized
      { nombre: '404', _id: '404' }, // Not Found
      { nombre: '500', _id: '500' }, // Unknown
    ],
    filter: {
      field: 'codigoRespuesta',
    },
    label: 'Codigo Respuesta',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public filtros: IFiltroTabla[] = [
    this.tipoMensaje,
    this.codigoRespuesta,
    this.fecha,
  ];
  // QUERY+
  private filter: IFilter<ILogNuc> = {};
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize(this.name),
    sort: '-fecha',
    populate: '',
  };
  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private route: ActivatedRoute,
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<ILogNuc>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    const filter: IFilter<ILogNuc> = { deveui: this.deveuiS };
    const existingFilter = JSON.parse(this.queryParams.filter || '{}');
    const combinedFilter = { ...existingFilter, ...filter };
    this.queryParams.filter = JSON.stringify(combinedFilter);

    this.datos$ = this.listadosService
      .subscribe<IListado<ILogNuc>>('logNucs', this.queryParams)
      .subscribe((data) => {
        const logs = data.datos as ILogTabla[];
        logs.forEach((log) => {
          log.color = this.getRandomColorFromString(log.tipo!);
        });

        this.totalCount = data.totalCount;
        this.datos = logs;
        //this.dataSource.data = logs;
        console.log(`listado de logNucs`, data);
      });
    await this.listadosService.getLastValue('logNucs', this.queryParams);
  }

  // Body

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fecha',
        },
      },
      {
        header: {
          label: 'Tipo de Mensaje',
          sortable: true,
        },
        row: {
          field: 'tipo',
        },
      },
      {
        header: {
          label: 'Tiempo de Respuesta',
          sortable: true,
        },
        row: {
          field: 'tiempoRespuesta',
          suffix: 'ms.',
        },
      },
      {
        header: {
          label: 'Codigo de Respuesta',
          sortable: true,
        },
        row: {
          field: 'codigoRespuesta',
        },
      },
      {
        header: {
          label: 'Body',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              icon: 'assets/iconos/buscarV.png',
              tooltip: 'Request',
              click: (dato) => this.mostrarBody(dato.body || {}),
              oculta(dato) {
                return dato.body == null;
              },
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/buscar.png',
              tooltip: 'Respuesta',
              click: (dato) => this.mostrarBody(dato.respuesta || {}),
              oculta(dato) {
                return dato.respuesta == null;
              },
            },
          ],
        },
      },
    ];
  }
  public async mostrarBody(data: ILogNuc) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      // disableClose: true,
    };
    this.matDialog.open(MostrarBodyComponent, config);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.loading = true;
      this.deveuiS = params.get('deveui') || '';
      await Promise.all([this.listar()]);
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }

  // Colores by Chat-GPT
  public getRandomColorFromString(str: string): string {
    let hash = 0;
    str = str + 'random2077';
    // Generate a hash code from the string
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash code to a hex color code
    const hex = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - hex.length) + hex;
  }
}
