import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IListado, ILogNuc, IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class LogNucService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ILogNuc>> {
    const params = HelperService.getQueryParams(queryParams);
    console.log(params);
    return this.http.get(`/logNuc`, { params });
  }

  public listarPorId(id: string): Observable<ILogNuc> {
    return this.http.get(`/logNuc/${id}`);
  }
}
