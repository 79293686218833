import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { LogNucRoutingModule } from './logNuc.routing.module';
import { ListadoComponent } from './listado/listado.component';
import { MostrarBodyComponent } from './listado/mostrar-body/mostrar-body.component';

@NgModule({
  declarations: [ListadoComponent, MostrarBodyComponent],
  imports: [CommonModule, AuxiliaresModule, LogNucRoutingModule],
})
export class LogNucModule {}
