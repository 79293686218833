<mat-card>
  <mat-card-title
    cdkDrag
    cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    style="text-align: center; margin: 1em"
  >
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content
    style="margin: 2em 0; max-height: 65vh; overflow: auto; margin-bottom: 5px"
  >
    <form
      *ngIf="form"
      id="loginForm"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
    >
      <!-- APN  -->
      <mat-form-field>
        <mat-label>APN</mat-label>
        <input matInput placeholder="APN" formControlName="apn" />
      </mat-form-field>

      <!-- Usuario  -->
      <mat-form-field>
        <mat-label>Usuario</mat-label>
        <input matInput placeholder="" formControlName="usuario" />
      </mat-form-field>

      <!-- Contraseña -->
      <mat-form-field>
        <mat-label>Contraseña</mat-label>
        <input matInput placeholder="" formControlName="password" />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin-bottom: 10px">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
