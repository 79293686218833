<div style="height: 100%; display: flex; flex-direction: column">
  <!-- NSP -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        NSP
      </h1>
      <div fxLayout="row" fxLayoutGap="10px" style="padding-right: 0.5em">
        <!-- Config NSP -->
        <img
          src="assets/iconos/config.png"
          alt="editar"
          matTooltip="Configurar"
          style="width: 30px; margin-right: 0.5em; cursor: pointer"
          *ngIf="
            helper.puedeConfigurarNSP() &&
            unidadPresion?.dispositivo?.tipoDispositivo === 'NSP'
          "
          (click)="configNsp(unidadPresion!.dispositivo!)"
        />
        <!-- Config Alertas NSP -->
        <img
          src="assets/iconos/alerta.png"
          alt="editar"
          *ngIf="
            helper.puedeConfigurarNSP() &&
            unidadPresion?.dispositivo?.tipoDispositivo === 'NSP'
          "
          matTooltip="Configurar Alertas"
          style="width: 30px; cursor: pointer"
          (click)="configAlertasNSP(unidadPresion!)"
        />
      </div>
    </div>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <table style="width: 100%">
      <!-- Device Name -->
      <tr>
        <th>Device</th>
        <td style="text-align: end">
          {{ unidadPresion?.dispositivo?.deviceName || "..." }}
        </td>
      </tr>
      <!-- Deveui -->
      <tr>
        <th>DevEUI</th>
        <td style="text-align: end">
          <span>{{ unidadPresion?.deveui || "..." }}</span>
        </td>
      </tr>
      <!-- Firmware -->
      <tr>
        <th>Firmware</th>
        <td style="text-align: end">
          {{ configActual?.firmwareNsp || "..." }}
        </td>
      </tr>
      <!-- Batería -->
      <tr>
        <th>Batería</th>
        <td style="text-align: end">
          {{ configActual?.voltajeBateria | number: "0.0-2" }} V.
        </td>
      </tr>
      <!-- Ultima Comunicacion -->
      <tr>
        <th>Última Comunicación</th>
        <td style="text-align: end">
          {{
            unidadPresion?.dispositivo?.fechaUltimaComunicacion
              | date: "short" || "..."
          }}
        </td>
      </tr>
      <!-- Medicion Instantanea -->
      <tr>
        <th>Presión Instantánea</th>
        <td style="text-align: end">
          {{
            unidadPresion?.dispositivo?.config?.medicionInstantanea
              | number: "0.0-2"
          }}
        </td>
      </tr>

      <!-- Config -->
      <ng-container>
        <!-- Separador -->
        <tr>
          <td colspan="100%">
            <mat-divider class="divider" style="margin: 1em 0"></mat-divider>
          </td>
        </tr>

        <!-- ACTUAL -->
        <!-- Modo Operacion -->
        <tr>
          <th>Modo de Operación</th>
          <td style="text-align: end">
            {{ textoModoOperacionNSP(configActual?.modoOperacion) }}
          </td>
        </tr>
        <!-- Hora Inicio -->
        <tr>
          <th>Hora de Inicio</th>
          <td style="text-align: end">
            {{
              configActual?.horaInicio
                ? "0" + ((configActual?.horaInicio || 9) - 3) + ":00 Hs."
                : "..."
            }}
          </td>
        </tr>
        <!-- Limite Min -->
        <tr>
          <th>Límite Mínimo</th>
          <td style="text-align: end">
            {{ configActual?.limiteMin | number: "1.0-2" }}
          </td>
        </tr>
        <!-- Limite Max -->
        <tr>
          <th>Límite Máximo</th>
          <td style="text-align: end">
            {{ configActual?.limiteMax | number: "1.0-2" }}
          </td>
        </tr>
        <!-- ICCID -->
        <tr>
          <th>ICCID</th>
          <td style="text-align: end">
            {{ configActual?.iccid || "..." }}
          </td>
        </tr>
        <!-- APN -->
        <tr>
          <th>APN</th>
          <td style="text-align: end">
            <div style="display: flex; justify-content: end">
              {{ configActual?.apn || "..." }}
              <mat-icon
                matTooltip="APN válido"
                *ngIf="configActual?.apn && configActual?.estadoAPN"
                style="color: green"
              >
                check_circle
              </mat-icon>
              <mat-icon
                matTooltip="APN Inválido"
                *ngIf="configActual?.apn && !configActual?.estadoAPN"
                style="color: red"
              >
                cancel
              </mat-icon>
            </div>
          </td>
        </tr>
        <tr>
          <th>Usuario</th>
          <td style="text-align: end">
            {{ configActual?.user || "..." }}
          </td>
        </tr>
        <!-- Contraseña -->
        <tr>
          <th>Contraseña</th>
          <td style="text-align: end">
            {{ configActual?.pass || "..." }}
          </td>
        </tr>
        <!-- Operadora -->
        <tr>
          <th>Operadora</th>
          <td style="text-align: end">
            {{ configActual?.operadora || "..." }}
          </td>
        </tr>
        <!-- Telefono 1 -->
        <tr>
          <th>Teléfono 1</th>
          <td style="text-align: end">
            {{ configActual?.telefono1 || "..." }}
          </td>
        </tr>
        <!-- Telefono 2 -->
        <tr>
          <th>Teléfono 2</th>
          <td style="text-align: end">
            {{ configActual?.telefono2 || "..." }}
          </td>
        </tr>
        <!-- Telefono 3 -->
        <tr>
          <th>Teléfono 3</th>
          <td style="text-align: end">
            {{ configActual?.telefono3 || "..." }}
          </td>
        </tr>
      </ng-container>
    </table>
  </div>

  <!-- Config no aplicada -->
  <div
    *ngIf="configAplicada === false"
    class="bg-accent"
    style="border-bottom-left-radius: 30px"
  >
    <div
      class="column-center"
      style="justify-content: center; padding: 0 0 0.5em 0"
    >
      <h3 style="padding-left: 0.5em">
        <strong> Configuración aún no aplicada</strong>
      </h3>
      <table style="width: 100%">
        <!-- ENV -->
        <tr *ngIf="env.toUpperCase() !== configDeseada?.modoEnv">
          <th>ENV</th>
          <td style="text-align: end">
            {{ configDeseada?.modoEnv }}
          </td>
        </tr>
        <!-- Modo Operacion -->
        <tr
          *ngIf="
            textoModoOperacionNSP(configActual?.modoOperacion) !==
            textoModoOperacionNSP(configDeseada?.modoOperacion)
          "
        >
          <th>Modo de Operación</th>
          <td style="text-align: end">
            {{ textoModoOperacionNSP(configDeseada?.modoOperacion) }}
          </td>
        </tr>
        <!-- Hora Inicio -->
        <tr *ngIf="configDeseada?.horaInicio !== configActual?.horaInicio">
          <th>Hora de Inicio</th>
          <td style="text-align: end">
            {{
              configDeseada?.horaInicio
                ? "0" + ((configDeseada?.horaInicio || 9) - 3) + ":00 Hs."
                : "..."
            }}
          </td>
        </tr>
        <!-- Limite Min -->
        <tr
          *ngIf="
            this.difLimite(configDeseada?.limiteMin, configActual?.limiteMin) >
            0.1
          "
        >
          <th>Limite Mínimo</th>
          <td style="text-align: end">
            <span
              *ngIf="configDeseada?.limiteMin || configDeseada?.limiteMin === 0"
            >
              {{ configDeseada?.limiteMin }} Bar
            </span>
          </td>
        </tr>
        <!-- Limite max -->
        <tr
          *ngIf="
            this.difLimite(configDeseada?.limiteMax, configActual?.limiteMax) >
            0.1
          "
        >
          <th>Limite Máximo</th>
          <td style="text-align: end">
            <span
              *ngIf="configDeseada?.limiteMax || configDeseada?.limiteMax === 0"
            >
              {{ configDeseada?.limiteMax }} Bar
            </span>
          </td>
        </tr>
        <!-- APN -->
        <tr *ngIf="configDeseada?.apn !== configActual?.apn">
          <th>APN</th>
          <td style="text-align: end">
            {{ configDeseada?.apn || "..." }}
          </td>
        </tr>
        <!-- User -->
        <tr *ngIf="configDeseada?.user !== configActual?.user">
          <th>Usuario</th>
          <td style="text-align: end">
            {{ configDeseada?.user || "..." }}
          </td>
        </tr>
        <!-- Contraseña -->
        <tr *ngIf="configDeseada?.pass !== configActual?.pass">
          <th>Contraseña</th>
          <td style="text-align: end">
            {{ configDeseada?.pass || "..." }}
          </td>
        </tr>
        <!-- Telefono 1 -->
        <tr *ngIf="configDeseada?.telefono1 !== configActual?.telefono1">
          <th>Teléfono 1</th>
          <td style="text-align: end">
            {{ configDeseada?.telefono1 || "..." }}
          </td>
        </tr>
        <!-- Telefono 2 -->
        <tr *ngIf="configDeseada?.telefono2 !== configActual?.telefono2">
          <th>Teléfono 2</th>
          <td style="text-align: end">
            {{ configDeseada?.telefono2 || "..." }}
          </td>
        </tr>
        <!-- Telefono 3 -->
        <tr *ngIf="configDeseada?.telefono3 !== configActual?.telefono3">
          <th>Teléfono 3</th>
          <td style="text-align: end">
            {{ configDeseada?.telefono3 || "..." }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
