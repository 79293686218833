<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="loginForm"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- modoEnv -->
      <mat-form-field *ngIf="esAdminGlobal">
        <mat-label>Entorno</mat-label>
        <mat-select formControlName="modoEnv">
          <mat-option *ngFor="let dato of modosEnv" [value]="dato.value">
            {{ dato.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- modoRegistros -->
      <mat-form-field>
        <mat-label>Modo de Operación</mat-label>
        <mat-select formControlName="modoOperacion">
          <mat-option *ngFor="let dato of modosOperacion" [value]="dato.value">
            {{ dato.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Hora de Inicio -->
      <mat-form-field>
        <mat-label>Hora de Inicio</mat-label>
        <mat-select formControlName="horaInicio">
          <mat-option *ngFor="let dato of horasInicio" [value]="dato.value">
            {{ dato.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Límite Mínimo  -->
      <mat-form-field>
        <mat-label>Límite Mínimo</mat-label>
        <input matInput type="number" formControlName="limiteMin" required />
      </mat-form-field>

      <!-- Límite Máximo  -->
      <mat-form-field>
        <mat-label>Límite Máximo</mat-label>
        <input matInput type="number" formControlName="limiteMax" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>APN</mat-label>
        <!--formControlName="apnControl"-->
        <mat-select
          [formControl]="apnControl"
          (selectionChange)="onSelectionChange($event)"
        >
          <mat-option *ngFor="let dato of apns" [value]="dato">
            APN: {{ dato.apn }}, Usuario: {{ dato.usuario }}, Contraseña:
            {{ dato.password }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Teléfono 1  -->
      <div fxLayout="row" fxLayoutGap="1em">
        <ng-select
          fxFlex="25"
          [items]="paises"
          placeholder="Pais"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="codigo1"
          bindLabel="pais"
          bindValue="codigo_telefonico"
          [appendTo]="'body'"
        >
        </ng-select>

        <mat-form-field>
          <mat-label>Teléfono 1</mat-label>
          <input
            matInput
            type="tel"
            maxlength="10"
            formControlName="telefono1"
          />
          <mat-hint> Ingrese el número de teléfono sin el 0 ni el 15 </mat-hint>
        </mat-form-field>
      </div>

      <!-- Teléfono 2  -->
      <div fxLayout="row" fxLayoutGap="1em">
        <ng-select
          fxFlex="25"
          [items]="paises"
          placeholder="Pais"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="codigo2"
          bindLabel="pais"
          bindValue="codigo_telefonico"
          [appendTo]="'body'"
        >
        </ng-select>

        <mat-form-field>
          <mat-label>Teléfono 2</mat-label>
          <input
            matInput
            type="tel"
            maxlength="10"
            formControlName="telefono2"
          />
          <mat-hint> Ingrese el número de teléfono sin el 0 ni el 15 </mat-hint>
        </mat-form-field>
      </div>

      <!-- Teléfono 3  -->
      <div fxLayout="row" fxLayoutGap="1em">
        <ng-select
          fxFlex="25"
          [items]="paises"
          placeholder="Pais"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="codigo3"
          bindLabel="pais"
          bindValue="codigo_telefonico"
          [appendTo]="'body'"
        >
        </ng-select>

        <mat-form-field>
          <mat-label>Teléfono 3</mat-label>
          <input
            matInput
            type="tel"
            maxlength="10"
            formControlName="telefono3"
          />
          <mat-hint> Ingrese el número de teléfono sin el 0 ni el 15 </mat-hint>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
