import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDispositivo,
  ICreateConfigDispositivo,
  IConfigDispositivo,
  IConfigDispositivoNSP4G,
  IApn,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ConfigDispositivosService } from '../config-dispositivos.service';
import { MODOS_OPERACION_NSP } from '../../../../environments/datos';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { LoginService } from '../../login/login.service';
import { ClientesService } from '../../apn/clientes.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-config-dispositivos-nsp',
  templateUrl: './config-dispositivos-nsp.component.html',
  styleUrls: ['./config-dispositivos-nsp.component.scss'],
})
export class ConfigDispositivosNspComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;
  public apns: IApn[] = [{}];
  public apnControl: FormControl = new FormControl();

  public esAdminGlobal = this.helper.esAdminGlobal();

  public config?: IConfigDispositivoNSP4G;

  public modosOperacion = MODOS_OPERACION_NSP;

  public modosEnv = [
    { value: 'TEST', viewValue: 'TEST' },
    { value: 'PROD', viewValue: 'PROD' },
  ];

  public horasInicio = [
    // +3 porque es UTC
    { value: 0 + 3, viewValue: '00:00 Hs.' },
    { value: 1 + 3, viewValue: '01:00 Hs.' },
    { value: 2 + 3, viewValue: '02:00 Hs.' },
    { value: 3 + 3, viewValue: '03:00 Hs.' },
    { value: 4 + 3, viewValue: '04:00 Hs.' },
    { value: 5 + 3, viewValue: '05:00 Hs.' },
    { value: 6 + 3, viewValue: '06:00 Hs.' },
    { value: 7 + 3, viewValue: '07:00 Hs.' },
    { value: 8 + 3, viewValue: '08:00 Hs.' },
  ];

  public paises = [
    { pais: 'Argentina', codigo_telefonico: '549' },
    { pais: 'Bolivia', codigo_telefonico: '591' },
    { pais: 'Brasil', codigo_telefonico: '55' },
    { pais: 'Chile', codigo_telefonico: '56' },
    { pais: 'Colombia', codigo_telefonico: '57' },
    { pais: 'Costa Rica', codigo_telefonico: '506' },
    { pais: 'Cuba', codigo_telefonico: '53' },
    { pais: 'Ecuador', codigo_telefonico: '593' },
    { pais: 'El Salvador', codigo_telefonico: '503' },
    { pais: 'Guatemala', codigo_telefonico: '502' },
    { pais: 'Honduras', codigo_telefonico: '504' },
    { pais: 'México', codigo_telefonico: '52' },
    { pais: 'Nicaragua', codigo_telefonico: '505' },
    { pais: 'Panamá', codigo_telefonico: '507' },
    { pais: 'Paraguay', codigo_telefonico: '595' },
    { pais: 'Perú', codigo_telefonico: '51' },
    // { pais: 'República Dominicana', codigo_telefonico: '1-809' },
    { pais: 'Uruguay', codigo_telefonico: '598' },
    { pais: 'Venezuela', codigo_telefonico: '58' },
  ];

  // Listado Continuo
  public config$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ConfigDispositivosNspComponent>,
    private service: ConfigDispositivosService,
    private listadosService: ListadosService,
    private helper: HelperService,
    private clientesService: ClientesService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Configuración del Dispositivo'
      : 'Crear Configuración del Dispositivo';

    console.log(`this.data`, this.data);

    const config = this.data?.config as IConfigDispositivoNSP4G;

    const codigo1 = config?.telefono1?.slice(0, -10);
    const telefono1 = config?.telefono1?.slice(-10);
    const codigo2 = config?.telefono2?.slice(0, -10);
    const telefono2 = config?.telefono2?.slice(-10);
    const codigo3 = config?.telefono3?.slice(0, -10);
    const telefono3 = config?.telefono3?.slice(-10);

    this.form = this.fb.group({
      horaInicio: [config?.horaInicio, Validators.required],
      modoEnv: [config?.modoEnv, Validators.required],
      modoOperacion: [config?.modoOperacion, Validators.required],
      limiteMin: [config?.limiteMin || 0, Validators.required],
      limiteMax: [config?.limiteMax || 10, Validators.required],
      codigo1: [codigo1 || '549'],
      telefono1: [telefono1, Validators.pattern(this.helper.regTel)],
      codigo2: [codigo2 || '549'],
      telefono2: [telefono2, Validators.pattern(this.helper.regTel)],
      codigo3: [codigo3 || '549'],
      telefono3: [telefono3, Validators.pattern(this.helper.regTel)],
      apn: [config?.apn],
      user: [config?.user],
      pass: [config?.pass],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  public onSelectionChange(event: MatSelectChange) {
    this.form?.patchValue({
      apn: event.value.apn.apn || event.value.apn,
      user: event.value.apn.user || event.value.usuario,
      pass: event.value.apn.pass || event.value.password,
    });
  }

  private getUpsertData() {
    const config: IConfigDispositivoNSP4G & {
      codigo1: string;
      codigo2: string;
      codigo3: string;
    } = this.form?.value;

    if (config.telefono1) {
      config.telefono1 = `${config.codigo1}${config.telefono1}`;
    }
    if (config.telefono2) {
      config.telefono2 = `${config.codigo2}${config.telefono2}`;
    }
    if (config.telefono3) {
      config.telefono3 = `${config.codigo3}${config.telefono3}`;
    }

    const data: ICreateConfigDispositivo = {
      deveui: this.data.deveui,
      config,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpsertData();
        console.log(`data`, data);
        await firstValueFrom(this.service.upsert(data));
        this.helper.notifSuccess('Configuración actualizada');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarConfigDispositivo(): Promise<void> {
    const deveui = this.data.deveui || '';
    this.config$?.unsubscribe();
    this.config$ = this.listadosService
      .subscribe<IConfigDispositivo>('configDispositivo', deveui)
      .subscribe((data) => {
        this.config = data?.config as IConfigDispositivoNSP4G;
        console.log(`listado de configDispositivo`, data);
      });
    await this.listadosService.getLastValue('configDispositivo', deveui);

    console.log('config' + JSON.stringify(this.config));
  }

  private updateForm() {
    this.form?.patchValue({ modoEnv: 'PROD' });
    if (this.config) {
      const configCodigo1 = this.config?.telefono1?.slice(0, -10);
      const configTelefono1 = this.config?.telefono1?.slice(-10);
      const configCodigo2 = this.config?.telefono2?.slice(0, -10);
      const configTelefono2 = this.config?.telefono2?.slice(-10);
      const configCodigo3 = this.config?.telefono3?.slice(0, -10);
      const configTelefono3 = this.config?.telefono3?.slice(-10);

      const actualCodigo1 = this.data?.config?.telefono1?.slice(0, -10);
      const actualTelefono1 = this.data?.config?.telefono1?.slice(-10);
      const actualCodigo2 = this.data?.config?.telefono2?.slice(0, -10);
      const actualTelefono2 = this.data?.config?.telefono2?.slice(-10);
      const actualCodigo3 = this.data?.config?.telefono3?.slice(0, -10);
      const actualTelefono3 = this.data?.config?.telefono3?.slice(-10);

      const indice = this.apns?.findIndex(
        (item) =>
          JSON.stringify(item) ==
          JSON.stringify({
            apn: this.config?.apn,
            usuario: this.config?.user,
            password: this.config?.pass,
          }),
      );
      this.apnControl = new FormControl(this.apns[indice]);
      this.form?.patchValue({
        horaInicio: this.config?.horaInicio || this.data?.config?.horaInicio,
        modoEnv: this.config?.modoEnv || this.data?.config?.modoEnv,
        modoOperacion:
          this.config?.modoOperacion || this.data?.config?.modoOperacion,

        limiteMin: this.config?.limiteMin || this.data?.config?.limiteMin,
        limiteMax: this.config?.limiteMax || this.data?.config?.limiteMax,
        codigo1: configCodigo1 || actualCodigo1 || '549',
        telefono1: configTelefono1 || actualTelefono1 || '',
        codigo2: configCodigo2 || actualCodigo2 || '549',
        telefono2: configTelefono2 || actualTelefono2 || '',
        codigo3: configCodigo3 || actualCodigo3 || '549',
        telefono3: configTelefono3 || actualTelefono3 || '',
        apn: this.config.apn,
        user: this.config?.user,
        pass: this.config?.pass,
      });
    }

    console.log(`this.form.value updated`, this.form?.value);
  }

  private async getApns() {
    const idCliente = LoginService.getUsuario().idCliente;
    let cliente;
    if (idCliente)
      cliente = await firstValueFrom(this.clientesService.getPropio());
    if (cliente) this.apns = cliente.config?.apns || [{}];
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await this.getApns();
    await Promise.all([this.listarConfigDispositivo()]);
    this.updateForm();
    console.log('this.data' + this.data);
  }

  ngOnDestroy(): void {
    this.config$?.unsubscribe();
  }
}
