import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApn, ICliente, IConfigCliente, IUpdateCliente } from 'modelos/src';
import { HelperService } from '../../../auxiliares/helper.service';
import { ClientesService } from '../clientes.service';
import { LoginService } from '../../login/login.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-crear-editar-apns',
  templateUrl: './crear-editar-apns.component.html',
  styleUrls: ['./crear-editar-apns.component.scss'],
})
export class CrearEditarApnsComponent implements OnInit {
  public form?: UntypedFormGroup;
  private idCliente?: string;
  private cliente?: ICliente;
  public title = '';
  private indice = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataForm: ICliente,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarApnsComponent>,
    private helper: HelperService,
    private service: ClientesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dato: IApn;
      callback: any;
    },
  ) {}

  private async createForm(): Promise<void> {
    this.form = this.fb.group({
      apn: this.data.dato?.apn || '',
      usuario: this.data.dato?.usuario || '',
      password: this.data.dato?.password || '',
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private async getData() {
    const dataForm: IUpdateCliente = {
      config: this.cliente?.config,
    };
    if (dataForm.config) {
      if (this.indice >= 0 && Array.isArray(dataForm?.config?.apns)) {
        dataForm.config.apns[this.indice] = this.form?.value;
      } else dataForm?.config?.apns?.push(this.form?.value);
    } else dataForm.config = { apns: [this.form?.value] };

    dataForm?.config?.apns?.sort((a, b) => {
      if (a.apn && b.apn) {
        if (a.apn < b.apn) {
          return -1;
        } else if (a.apn > b.apn) {
          return 1;
        } else {
          return 0;
        }
      } else return 0;
    });
    return dataForm;
  }

  public async onSubmit(): Promise<void> {
    try {
      const dataForm = await this.getData();
      if (this.idCliente) {
        await firstValueFrom(this.service.editar(this.idCliente, dataForm));
      }
      this.data.callback();
      if (this.indice >= 0) this.helper.notifSuccess('Editado correctamente');
      else this.helper.notifSuccess('Creado correctamente');
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  private async getIndex(apn: IApn) {
    let cliente;
    if (this.idCliente)
      cliente = await firstValueFrom(this.service.listarPorId(this.idCliente));
    const data: IUpdateCliente = {
      config: cliente?.config,
    };
    const indice = data.config?.apns?.findIndex(
      (item) => JSON.stringify(item) == JSON.stringify(apn),
    );
    if (indice != undefined) this.indice = indice;
  }

  async ngOnInit(): Promise<void> {
    this.idCliente = LoginService.getUsuario().idCliente;
    await this.createForm();
    if (this.data.dato) {
      this.title = 'Editar APN';
      await this.getIndex(this.data.dato);
    } else this.title = 'Crear APN';
    this.cliente = await firstValueFrom(
      this.service.listarPorId(this.idCliente || ''),
    );
    //await this.listarApns();
  }
}
