import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ClientesRoutingModule } from './clientes.routing.module';
import { CrearEditarApnsComponent } from './crear-editar-apns/crear-editar-apns.component';
import { ListadoApnsComponent } from './listado-apns/listado-apns.component';
import { MatMenu } from '@angular/material/menu';

@NgModule({
  declarations: [CrearEditarApnsComponent, ListadoApnsComponent],
  imports: [CommonModule, AuxiliaresModule, ClientesRoutingModule, MatMenu],
})
export class ClientesModule {}
